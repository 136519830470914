<template>
  <span v-b-tooltip.hover :title="getMelissaCode(code)">{{ code }}{{ isSeperated ? ',&nbsp;' : '' }}</span>
</template>

<script>
import melissaCodes from '@/core/common/melissa-error-codes.config.json';

export default {
  props: {
    code: { type: String, required: true },
    isSeperated: { type: Boolean, default: true },
  },
  methods: {
    getMelissaCode(code) {
      return melissaCodes[code].LONG || '-';
    },
  },
};
</script>
